.article__sharing {
  margin: map-get($spacers, 4) 0;
}
.article__section-navigator {
  padding-top: map-get($spacers, 3);
  margin: map-get($spacers, 4) 0 map-get($spacers, 3) 0;
  word-wrap: break-word;
  @include split-line(top, 4px);
  & > .previous, & > .next {
    width: 50%;
    & > span {
      font-weight: map-get($base, font-weight-bold);
      color: $text-color-l;
    }
    & > a {
      display: block;
      @include link-colors($text-color, $main-color-1);
    }
  }
  & > .previous {
    float: left;
    padding-right: map-get($spacers, 2);
  }
  & > .next {
    float: right;
    padding-left: map-get($spacers, 2);
    text-align: right;
  }
}

.person_image {
  position: relative;
}

.person_image::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.person_image > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}